import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "About Casey",
  "slug": "about",
  "cover": "./tulips.jpg",
  "color": "#bfd8d2"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Hi, I'm Casey Lauren, I love making people feel pretty by enhancing their `}<em parentName="h2">{`natural beauty`}</em>{`.`}</h2>
    <p>{`I'm a traveling, full-service, professional beautician based in Orange County, CA.`}</p>
    <p>{`After 5 years in the beauty industry, I've found that I work best for my clients when I allow myself to work within their schedule.`}</p>
    <p>{`Flexibility is key, and my clients breathe easy knowing I'll arrive with everything necessary to get them looking good without leaving their home or office.`}</p>
    <p>{`Events are a passion of mine, I enjoy being a part of such special moments and I love helping bring out your best `}<strong parentName="p">{`you`}</strong>{`.`}</p>
    <p>{`Please, don't be shy. Feel free to reach out with any questions.`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      